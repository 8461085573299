<template>
  <div class="grid">
    <Toast></Toast>
    <div class="col-12">
      <Card>
        <template #title> "Ахметтану" интеллектуалды жүйесі </template>
        <template #content>
          <img src="/images/Ahmet.jpeg" class="img" />
          <span
            >Қазақ тілінің цифрлық форматтағы жұмсалымы мен қолданыс аясын
            кеңейту – еліміздің қазіргі стратегиялық даму бағытындағы маңызды
            әрі өзекті мәселелердің бірі. Бұл бағытта ұлт руханиятының көсемі,
            ағартушы, ғалым Ахмет Байтұрсынұлының тіл жүйесіне қатысты еңбектері
            тұрғысынан тілдің барлық құрылымдық қабаттары бойынша білімнің
            онтологиялық моделінен тұратын цифрландырылған білім базасын құрудың
            да маңызы зор. Ахмет Байтұрсынұлының ғылыми мұрасы бойынша
            цифрланған материалдарды қамтитын «Ахметтану» интеллектуалды жүйесі
            тілдің құрылымдық қабаттары бойынша білімнің онтологиялық моделінен,
            жаңа цифрланған материалдарды қосу және түзету функциясы бар қазақ
            тіліндегі қолданушылық интерфейстен тұрады. Ғалымның «Тіл-құрал»
            еңбегі бойынша цифрланған материалдар тілдің дыбыс, сөз және сөйлем
            жүйесіне қатысты жаңа білім жүйесін қамтиды. Бұл жүйенің басты
            мақсаты – Ахмет Байтұрсынұлының ғылыми мұрасын насихаттау арқылы
            қазақ тілінің дамуына әсер ететін цифрландырылған білімді ақпараттық
            құралдар арқылы әрі қарай дамытуға ықпал ету. Ғалымның ғылыми
            мұраларының цифрлық тұрғыда жүйеленуін де интеллектуалдық жүйенің
            артықшылығы ретінде атап көрсетуге болады. Ахмет Байтұрсынұлының
            тұлғасы әр түрлі ғылым салалары бойынша жазған еңбектерінің
            төмендегідей жүйесі арқылы айқындалады:
          </span>
          <div>
            <ul class="list">
              <li>
                <span class="button" id="1"
                  >АҒАРТУШЫ <i class="pi pi-tag"></i></span
                >,
                <span class="button" id="2"
                  >ҰЛТ ҰСТАЗЫ <i class="pi pi-tag"></i></span
                >,
                <span class="button" id="3"
                  >ҚОҒАМ ҚАЙРАТКЕРІ <i class="pi pi-tag"></i
                ></span>
              </li>
              <li>
                <span class="button" id="4"
                  >ТІЛТАНУШЫ <i class="pi pi-tag"></i></span
                >,
                <span class="button" id="5"
                  >ТҮРКІТАНУШЫ <i class="pi pi-tag"></i></span
                >,
                <span class="button" id="6"
                  >ТЕРМИНТАНУШЫ <i class="pi pi-tag"></i
                ></span>
              </li>
              <li>
                <span class="button" id="7"
                  >ҚАЗАҚ ЖАЗУЫ МЕН ЕМЛЕСІНІҢ РЕФОРМАТОРЫ
                  <i class="pi pi-tag"></i
                ></span>
              </li>
              <li>
                <span class="button" id="8"
                  >ӘДЕБИЕТТАНУШЫ <i class="pi pi-tag"></i></span
                >,
                <span class="button" id="9"
                  >ФОЛЬКЛОРТАНУШЫ <i class="pi pi-tag"></i></span
                >,
                <span class="button" id="10"
                  >ӨНЕРТАНУШЫ <i class="pi pi-tag"></i
                ></span>
              </li>
              <li>
                <span class="button" id="11"
                  >АҚЫН <i class="pi pi-tag"></i></span
                >,
                <span class="button" id="12"
                  >ЖАЗУШЫ <i class="pi pi-tag"></i></span
                >,
                <span class="button" id="13"
                  >ПУБЛИЦИСТ <i class="pi pi-tag"></i></span
                >,
                <span class="button" id="14"
                  >АУДАРМАШЫ <i class="pi pi-tag"></i
                ></span>
              </li>
              <li>
                <span class="button" id="15"
                  >ӘДІСКЕР <i class="pi pi-tag"></i></span
                >,
                <span class="button" id="16"
                  >ПЕДАГОГ <i class="pi pi-tag"></i></span
                >,
                <span class="button" id="17"
                  >ПСИХОЛОГ <i class="pi pi-tag"></i
                ></span>
              </li>
              <li>
                <span class="button" id="18"
                  >ЗАҢГЕР <i class="pi pi-tag"></i></span
                >,
                <span class="button" id="19"
                  >САЯСАТКЕР <i class="pi pi-tag"></i></span
                >,
                <span class="button" id="20"
                  >ДЕМОГРАФ <i class="pi pi-tag"></i></span
                >,
                <span class="button" id="21"
                  >ТАРИХШЫ <i class="pi pi-tag"></i
                ></span>
              </li>
            </ul>
          </div>
          <span 
            >«Ахметтану» интеллектуалды жүйесін пайдаланушы А.Байтұрсынұлының
            ғылыми еңбектерімен танысып, ғалымның қазақ тілінің деңгейлері
            бойынша ұсынған негізгі ұғымдары және түсініктері, ғылыми
            пайымдаулары туралы қажет ақпарат алады. «Ахметтану» интеллектуалды
            жүйесінің сипаттамасы: 
          </span>
          <ul class="temp-list">
            <li class="temp-list">
              «Тұлғасы» бөлімінде жүйе сипаттамасы және
            Ахмет Байтұрсынұлының әр түрлі ғылым салалары бойынша жазған
            еңбектері негізінде тұлғасы анықталып, сол тұлғасын анықтайтын
            еңбектері көрсетілген
            </li>
            <li class="temp-list">
              «Ғалымның мұрасы» бөлімінде А.Байтұрсынұлының
              ғылыми еңбектері бағыттар бойынша жүйеленіп көрсетілген.
            </li>
            <li class="temp-li">
              «Тіл-құрал» бөлімінде ғалымның қазақ тілінің деңгейлері бойынша
              ұсынған негізгі ұғымдары мен түсініктері және ғылыми пайымдаулары
              онтологиялық модель арқылы жүйеленіп орналастырылған
            </li>
            <li class="temp-li">
              «Интеллектуалды жүйе» бөлімінде пайдаланушының ғалымның еңбектері
              бойынша тілдің құрылымдық деңгейлері туралы сұраққа жауап алу
              мүмкіндігі бар
            </li>
            <li class="temp-li">
              «Терминдер мен анықтамалар» бөлімінде ғалымның қазақ тілінің
              деңгейлері бойынша ұсынған негізгі ұғымдары мен олардың
              анықтамалары бар
            </li>
          </ul>
        </template>
      </Card>
    </div>
    <Dialog
      v-model:visible="showDialog"
      :style="{ width: '80vw', maxHeight: '80vh' }"
      :header="selectedWord"
      :modal="true"
      class="p-0"
    >
      <div class="scrollable-content">
        <div v-for="data in repo" :key="data.id" class="card">
          <div class="row" style="justify-content: space-between">
            <div class="row">
              <i class="pi pi-file"></i>
              <div class="ml-2">
                {{ data.label }}
              </div>
            </div>
            <Button
              type="button"
              icon="pi pi-download"
              @click="searchBook(data.key)"
              rounded
            />
          </div>
        </div>
      </div>
    </Dialog>
  </div>
  <!-- #optiongroup="slotProps" -->
</template>

<script>
import vad from "voice-activity-detection";
import axios from "axios";
import { getWaveBlob } from "./webm-to-wav-converter";
import { faWindowRestore } from "@fortawesome/free-solid-svg-icons";
import { AhmetService } from "@/service/AhmetService";
import { AHMET_API } from "../config";
export default {
  name: "AudioSpeechRecognition",
  mounted() {
    //AhmetService.getFile(4)
    // console.log("mounted");
    // Select the buttons and add event listeners
    const buttons = document.querySelectorAll(".button");
    buttons.forEach((button) => {
      button.addEventListener("click", async () => {
        this.selectedWord = button.innerText.trim();
        console.log(`${this.selectedWord} button clicked`);
        await axios
          .post(`${AHMET_API}/search/book/file/`, {
            global: this.selectedWord,
          })
          .then((resp) => {
            this.repo = resp.data;
            console.log(this.repo);
            // this.label = resp.data[0].label;
            // this.key = resp.data[0].key;
          });
        if (this.repo == null || this.repo.length < 1) {
          this.$toast.add({
            severity: "error",
            summary: "Табылмады",
            detail: "Тегтер табылмады",
            life: 3000,
          });
        } else {
          this.showDialog = true;
        }
      });
    });
  },
  data() {
    return {
      vad: vad,
      repo: null,
      audioContext: null,
      showDialog: false,
      stateText: "s",
      valueText: "v",
      mediaRecorderGlob: null,
      streamGlob: null,
      blobsGlob: [],
      recording: false,
      speechText: "",
      translatedText: "",
      resultTemp: "",
      context: null,
      stopEnable: false,
      filename: this.$t("common.select"),
      audioFile: null,
      OntNames: [],
      selectedOnto: "",
      selectedWord: "",
      response: null,
      label: "Баталар.pdf",
      data: "",
      key: 4,
    };
  },
  methods: {
    searchBook(key) {
      AhmetService.getFile(key);
    },
    getJson() {
      axios
        .get(`${AHMET_API}/getontology/`)
        .then((response) => (this.OntNames = response.data));
    },
    uploadAudioFile() {
      if (this.$refs.audioFile.files.length > 0) {
        this.audioFile = this.$refs.audioFile.files[0];

        this.filename = this.audioFile.name;
        var fileReader = new FileReader();
        var app = this;
        fileReader.onload = function () {
          var arrayBuffer = this.result;
          app.medaiToWav(arrayBuffer);
        };
        fileReader.readAsArrayBuffer(this.audioFile);
        //let audioBtn = this.$refs.audioBtn.$el.querySelector('button');
        //audioBtn.focus()
        // console.log(document.getElementById("audioBtn"));

        document.getElementById("audioBtn").focus();
      }
    },
    async requestMic() {
      this.recording = true;
      // console.log(this.$salem);
      try {
        window.AudioContext = window.AudioContext || window.webkitAudioContext;
        this.audioContext = new AudioContext();
        faWindowRestore.audioContext = this.audioContext;
        navigator.getUserMedia =
          navigator.getUserMedia ||
          navigator.webkitGetUserMedia ||
          navigator.mozGetUserMedia ||
          navigator.msGetUserMedia;
        this.$media.stream = await navigator.getUserMedia(
          { audio: true, video: false },
          this.startUserMedia,
          this.handleMicConnectError
        );
      } catch (e) {
        this.handleUserMediaError();
      }
    },
    handleUserMediaError() {
      console.warn("Mic input is not supported by the browser.");
    },
    handleMicConnectError() {
      console.warn(
        "Could not connect microphone. Possible rejected by the user or is blocked by the browser."
      );
    },
    startUserMedia(stream) {
      var app = this;
      const mime = [
        "audio/wav",
        "audio/mpeg",
        "audio/webm",
        "audio/ogg",
      ].filter(MediaRecorder.isTypeSupported)[0];
      app.$media.recorder = new MediaRecorder(stream, {
        mimeType: mime,
      });
      app.$media.recorder.ondataavailable = function (e) {
        app.$media.blobs.push(e.data);
      };
      app.$media.recorder.start();
      app.$media.recorder.onstop = function () {
        //var blob = new Blob(app.$media.blobs, { 'type' : 'audio/wav;' });
        getWaveBlob(app.$media.blobs, false, app.audioContext).then((e) => {
          app.sendSpeechToTranslate(e);
        });

        app.$media.blobs = [];
      };
      var options = {
        onVoiceStart: function () {
          if (app.$media.recorder.state !== "recording")
            app.$media.recorder.start();
          //app.stopEnable = false;
          // console.log("voice start");
        },
        onVoiceStop: function () {
          app.stopEnable = true;
          // console.log("voice stop");
          // console.log(app.$media.recorder);
          if (app.$media.recorder.state === "recording") {
            app.$media.recorder.stop();
            app.$media.recorder.start();
          }
        },
        onUpdate: function (val) {
          var as = val;
          val = as;
        },
      };

      vad(this.audioContext, stream, options);
    },
    stopRecording() {
      this.$media.recorder.stop();
      this.recording = false;
      if (this.audioContext) {
        this.audioContext.close();
        this.audioContext = null;
      }
    },
    sendSpeechToTranslate(blob) {
      var data = new FormData();
      data.append("speech", blob);
      axios
        .post("http://localhost:8000/speechtt", data, {
          headers: {
            "Access-Control-Allow-Credentials": "true",
            "Content-Type": "multipart/form-data",
            mode: "no-cors",
          },
        })
        .then((response) => {
          if (response.data.text) {
            this.speechText += response.data.text + " ";
            this.translatedText += response.data.translatedText + " ";
            this.textToSpeech(response.data.translatedText, "ru-RU");
          }
        });
    },
    textToSpeech(text, languageCode) {
      axios
        .post(
          "http://localhost:8000/ttspeech",
          { text: text, langCode: languageCode },
          {
            headers: {
              "Access-Control-Allow-Credentials": "true",
              "Content-Type": "application/json",
              mode: "no-cors",
            },
            responseType: "blob",
          }
        )
        .then((response) => {
          var clipContainer = document.createElement("article");
          var audio = document.createElement("audio");

          clipContainer.classList.add("clip");
          audio.setAttribute("controls", "");
          audio.controls = true;
          audio.src = window.URL.createObjectURL(
            new Blob([response.data], { type: "audio/mpeg" })
          );
          clipContainer.appendChild(audio);
          this.$refs.clips.insertBefore(audio, this.$refs.clips.firstChild);
        });
    },
    medaiToWav(mp3data) {
      var app = this;
      var audioContext = new (window.AudioContext ||
        window.webkitAudioContext)();
      audioContext.decodeAudioData(mp3data, function (buffer) {
        getWaveBlob(mp3data, false, audioContext, buffer).then((e) => {
          app.audioFile = e;
        });
      });
    },
  },
};
</script>
<style scoped>
.row {
  display: flex;
  align-items: center;
}
.row div {
  margin-right: 10px;
}
.custom-file-upload {
  border: 1px solid #ccc;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
  width: inherit;
}
.button {
  display: inline-block;
  padding: 6px 12px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  border: 1px solid #ccc;
  border-radius: 4px;
  color: blue;
  background-color: #fff;
  text-decoration: none;
}
.button:hover {
  background-color: #f5f5f5;
  border-color: #adadad;
}
.scrollable-content {
  max-height: calc(80vh - 100px);
  overflow-y: auto;
}

.img {
  float: left;
  margin: 0 10px 0 0;
  width: 150px;
}
.list {
  list-style: none;
}
/* .temp-list {
  list-style-type: none; 
} */

.temp-li {
  font-weight: 400;
  margin-top: 10px; /* Adjust the margin as per your preference */
}
/* ul li {
  list-style: none; */
/* } */
</style>
